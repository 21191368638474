<script setup lang="ts">
import { paymentApi } from "@/api/paymentApi";
import NbaPlayoffsImage from "@/assets/images/nba-playoffs/nba_playoffs_1x.webp";
import { useAuthStore } from "@/stores/authStore";
import { launchConfetti } from "@/utils/confetti";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import SvgIcon from "../components/SvgIcon.vue";
import BlackCheckIcon from "../components/icons/BlackCheckIcon.vue";

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const hasPayment = ref(false);
const marchMadnessVisible = ref(false);
const directPaymentMessage = ref(false);
const loaded = ref(false);
const componentKey = ref(0);
const loading = ref(false);
const confettiTimes = ref(2);

const checkPayment = async () => {
  if (route.query.directp && route.query.payment == "success") {
    localStorage.setItem("4c_march_madness_served", "true");
    marchMadnessVisible.value = false;
    directPaymentMessage.value = true;
    showConfetti();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "subscribeFE",
      ecommerce: {
        value: 19.99,
        currency: "USD",
      },
    });
  }

  if (route.query.payment == "success" && route.query.user) {
    console.log("HERE 1 --------------------")
    console.log("HERE 1 --------------------")
    if (authStore.user.id == route.query.user) {
      console.log("HERE 2 --------------------")
      console.log("HERE 2 --------------------")
      localStorage.setItem("4c_march_madness_served", "true");
      marchMadnessVisible.value = false;
      hasPayment.value = true;
      try {
        const user = await paymentApi.checkPayment(authStore.user);
        if (user) {
          console.log("HERE 3 --------------------")
          console.log("HERE 3 --------------------")
          const query = { ...route.query };
          delete query.payment;
          delete query.user;
          router.replace({ path: route.path, query });
          showConfetti();
          try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "subscribeFE",
              ecommerce: {
                value: 19,
                currency: "USD",
              },
            });
          } catch (error) {
            console.error("Error pushing to dataLayer", error);
          }
        }
      } finally {
        hasPayment.value = false;
        loaded.value = true;
        return;
      }
    }
  }
  const query = { ...route.query };
  delete query.payment;
  delete query.user;
  router.replace({ path: route.path, query });
  hasPayment.value = false;
  loaded.value = true;
  return;
};

const requestPaymentLink = async () => {
  const link = `${window.location.origin}`;
  const result = await paymentApi.requestLink(authStore.user, link);
  window.location.href = result.payment_url;
};

const handleMarchMadness = async () => {
  loading.value = true;
  if (authStore.user) {
    requestPaymentLink();
  } else {
    loading.value = false;
    marchMadnessVisible.value = false;
    authStore.setAuthAndGoPay(true);
    authStore.setAuthModalVisible(true);
  }
};

const showMarchMadness = () => {
  if (
    !hasPayment.value &&
    !window.location.href.includes("march-madness") &&
    (!authStore.user || !authStore.user.has_access)
  ) {
    return true;
  }
  return false;
};

watch(
  () => authStore.user,
  () => checkPayment(),
);

const showConfetti = () => {
  confettiTimes.value = 2;
  setInterval(() => {
    if (confettiTimes.value > 0) {
      launchConfetti();
      confettiTimes.value -= 1;
    }
  }, 500);
};

onMounted(() => {
  const isFirstVisit = localStorage.getItem("4c_march_madness_served") === null;
  //TODO:: Check if user has a subscription - if not, show popup
  if (showMarchMadness() && isFirstVisit) {
    setTimeout(() => {
      localStorage.setItem("4c_march_madness_served", "true");
      marchMadnessVisible.value = true;
    }, 3000);
  }
});

const openAuthModal = (variant: string): void => {
  directPaymentMessage.value = false;
  authStore.setActiveModal(variant);
  authStore.setAuthModalVisible(true);
};
</script>

<template>
  <slot :key="componentKey"></slot>
  <Dialog
    :visible="hasPayment"
    :style="{ maxWidth: '250px', width: '100%' }"
    :modal="true"
    :closable="false"
    :closeOnEscape="false"
  >
    <div class="inline-flex w-full items-center justify-between gap-4 pb-5">
      <div class="shrink grow basis-0 text-base font-medium leading-loose text-slate-700">
        Confirming payment...
      </div>
      <div>
        <i class="pi pi-spin pi-spinner text-xl"></i>
      </div>
    </div>
  </Dialog>
  <Dialog
    :visible="directPaymentMessage"
    :style="{ maxWidth: '540px', width: 'calc(100% - 1rem)', margin: '0 auto' }"
    :modal="true"
    :closable="false"
    :closeOnEscape="false"
  >
    <div class="inline-flex w-full items-center justify-between gap-4 pb-5">
      <div class="shrink grow basis-0 text-base font-medium leading-loose text-slate-700">
        <div
          class="justify-start self-stretch pb-4 text-2xl font-semibold leading-[31.20px] text-slate-950"
        >
          Congratulations! You have Premium Access!
        </div>
        <p class="font-normal">To get started, please check your email for your login details.</p>
        <p class="font-bold">We have sent you a password reset link.</p>
        <div class="flex flex-col items-start justify-start gap-4 self-stretch px-6 lg:p-6">
          <Button
            @click="openAuthModal('login')"
            :disabled="loading"
            class="mt-3 w-full px-[11px] py-[11px] shadow"
          >
            <i v-if="loading" class="pi pi-spin pi-spinner"></i>
            <div class="text-xl font-bold">Start Winning Now!</div>
          </Button>
        </div>
      </div>
    </div>
  </Dialog>

  <Dialog
    :visible="marchMadnessVisible"
    :style="{ maxWidth: '540px', width: 'calc(100% - 1rem)', margin: '0 auto' }"
    :modal="true"
    :closable="false"
    :closeOnEscape="false"
    class="md:rounded-[32px]"
  >
    <template #header>
      <div class="inline-flex w-full items-start justify-between gap-4">
        <div class="leading-2 shrink grow basis-0 text-lg font-medium text-slate-700"></div>
        <div class="flex w-full flex-col items-center justify-center gap-2.5 self-stretch">
          <!-- <img class="h-[60px]" :src="MadnessImage" /> -->
          <img class="h-[60px]" :src="NbaPlayoffsImage" />
        </div>
        <Button
          @click="marchMadnessVisible = false"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
        >
          <div class="relative h-3.5 w-3.5">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>

    <div
      class="inline-flex w-full flex-col items-start justify-start gap-4 self-stretch overflow-x-hidden px-2 lg:gap-6"
    >
      <!-- <div class="flex flex-col items-center justify-center gap-2.5 self-stretch">
        <img class="h-[60px]" :src="MadnessImage" />
      </div> -->
      <div class="flex flex-col items-start justify-start gap-1 self-stretch">
        <div
          class="justify-start self-stretch text-2xl font-semibold leading-[31.20px] text-slate-950"
        >
          <!-- March Madness Special - Limited Offer! -->
          NBA Playoffs Special - Limited Offer!
        </div>
        <div class="justify-start self-stretch text-base font-normal leading-normal text-slate-950">
          <!-- Get EVERY AI predictive model on 4C Predictions. -->
          Get EVERY sports prediction on 4C Predictions.
        </div>
      </div>
      <div class="flex flex-col items-start justify-center gap-1 self-stretch">
        <div class="inline-flex items-center justify-start gap-1">
          <div
            class="justify-start text-[30px] font-semibold leading-10 text-slate-950 lg:text-[40px]"
          >
            $19
          </div>
          <div class="justify-end text-sm font-normal leading-none text-slate-950">/ month</div>
        </div>
        <div class="inline-flex items-center justify-start gap-2">
          <div class="justify-start text-sm font-normal leading-[21px] text-slate-950 line-through">
            $199 / month
          </div>
          <div
            class="flex items-center justify-center gap-2 rounded-[100px] bg-slate-950 px-4 py-1"
          >
            <div class="justify-start text-xs font-normal leading-[18px] text-white">
              For the first 777 Customers.
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start gap-2">
        <div class="inline-flex h-6 max-w-[491px] items-start justify-start gap-1">
          <div class="flex items-center justify-center gap-2.5 p-1">
            <div class="relative h-4 w-4">
              <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
            </div>
          </div>
          <div class="flex-1 justify-start">
            <!-- <span class="text-base leading-normal text-slate-950"><b>All Sports,</b> Every AI Prediction.</span> -->
            <span class="text-base leading-normal text-slate-950"
              ><b>All Sports Predictions.</b></span
            >
          </div>
        </div>
        <div class="inline-flex h-6 max-w-[491px] items-start justify-start gap-1">
          <div class="flex items-center justify-center gap-2.5 p-1">
            <div class="relative h-4 w-4 overflow-hidden">
              <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
            </div>
          </div>
          <div class="flex-1 justify-start">
            <span class="text-base font-semibold leading-normal text-slate-950">78% </span>
            <span class="text-base font-normal leading-normal text-slate-950">Accurate </span>
            <span class="text-base font-semibold leading-normal text-slate-950">AI Models</span>
            <span class="text-base font-normal leading-normal text-slate-950">.</span>
          </div>
        </div>
        <div class="inline-flex h-6 max-w-[491px] items-start justify-start gap-1">
          <div class="flex items-center justify-center gap-2.5 p-1">
            <div class="relative h-4 w-4">
              <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
            </div>
          </div>
          <div class="flex-1 justify-start">
            <span class="text-base font-semibold leading-normal text-slate-950">Instant Picks</span
            ><span class="text-base font-normal leading-normal text-slate-950">, No Delays.</span>
          </div>
        </div>
        <div class="inline-flex h-6 max-w-[491px] items-start justify-start gap-1">
          <div class="flex items-center justify-center gap-2.5 p-1">
            <div class="relative h-4 w-4">
              <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
            </div>
          </div>
          <div class="flex-1 justify-start">
            <span class="text-base font-normal leading-normal text-slate-950">Get the edge </span
            ><span class="text-base font-semibold leading-normal text-slate-950"
              >sports books fear.</span
            >
          </div>
        </div>
        <div class="inline-flex h-6 max-w-[491px] items-start justify-start gap-1">
          <div class="flex items-center justify-center gap-2.5 p-1">
            <div class="relative h-4 w-4">
              <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
            </div>
          </div>
          <div class="flex-1 justify-start">
            <span class="text-base font-semibold leading-normal text-slate-950">Sharp bettors </span
            ><span class="text-base font-normal leading-normal text-slate-950">use AI.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-start justify-start gap-4 self-stretch px-6 lg:p-6">
      <Button
        @click="handleMarchMadness"
        :disabled="loading"
        class="mt-3 w-full px-[11px] py-[11px] shadow"
      >
        <i v-if="loading" class="pi pi-spin pi-spinner"></i>
        <div class="text-xl font-bold">Start Winning Now!</div>
      </Button>
      <div
        class="justify-start self-stretch text-center text-xs font-normal leading-[18px] text-slate-950"
      >
        Billed Monthly | Cancel Anytime
      </div>
    </div>
  </Dialog>
</template>
