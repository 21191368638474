<script setup lang="ts">
import { paymentApi } from "@/api/paymentApi";
import { useAuthStore } from "@/stores/authStore";
import BlackCheckIcon from "@/ui/components/icons/BlackCheckIcon.vue";
import AuthLayout from "@/ui/layouts/AuthLayout.vue";
import { onMounted, ref, watch } from "vue";

import BaseballImage from "@/assets/images/register/baseball_bg_2.webp";
import BasketballImage from "@/assets/images/register/basketball_bg_2.webp";
import DefaultImage from "@/assets/images/register/default_bg_2.webp";
import FootballImage from "@/assets/images/register/football_bg_2.webp";
import SoccerImage from "@/assets/images/register/soccer_bg_2.webp";
import { useRoute, useRouter } from "vue-router";

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const league = ref("");
const target = ref("");
const bgImage = ref("");

const requestPaymentLink = async () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "initiateCheckout",
  });

  loading.value = true;
  const link =
    target.value.toLowerCase() !== "default"
      ? `${window.location.origin}/events/${target.value}`
      : window.location.origin;
  const result = await paymentApi.requestLink(authStore.user, link);
  window.location.href = result.payment_url;
};

watch(
  () => route.query.target,
  () => {
    const _target = route.query.target as string;
    target.value = _target?.toLowerCase() || "default";

    if (target.value) {
      switch (target.value.toLowerCase()) {
        case "soccer":
          league.value = "Premier League";
          bgImage.value = SoccerImage;
          break;
        case "baseball":
          league.value = "Major League Baseball";
          bgImage.value = BaseballImage;
          break;
        case "basketball":
          league.value = "NBA Playoffs";
          bgImage.value = BasketballImage;
          break;
        case "football":
          league.value = "NFL";
          bgImage.value = FootballImage;
          break;
        default:
          league.value = "NBA Playoffs";
          bgImage.value = DefaultImage;
      }
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (!authStore.user) {
    router.push({ name: "home" });
  }
});
</script>

<template>
  <AuthLayout :variant="target" :bgImage="bgImage">
    <div class="justify-start self-stretch text-center">
      <span class="text-[32px] font-medium leading-10 text-slate-950"
        >Don't Just Watch - <strong>Win with AI!</strong></span
      >
    </div>
    <div class="mx-auto w-full max-w-[570px] justify-center self-stretch text-center">
      <span class="text-sm font-normal leading-[21px] text-slate-950"
        >Get the <b>{{ league }} Special</b> and <b>join over 50,000 satisfied users</b> crushing
        the odds and <b>WINNING</b> with <b>real-time insights</b>.
      </span>
    </div>
    <div
      class="flex w-full max-w-[400px] flex-col items-end justify-start overflow-hidden rounded-xl bg-white shadow-xl outline outline-1 outline-offset-[-1px] outline-slate-200"
    >
      <div class="flex flex-col items-start justify-start gap-6 self-stretch px-6 pt-6">
        <div class="flex flex-col items-start justify-start gap-1 self-stretch">
          <div
            class="justify-start self-stretch text-xl font-semibold leading-relaxed text-slate-950"
          >
            {{ league }} Special
          </div>
          <div class="justify-start self-stretch text-sm font-normal leading-[21px] text-slate-950">
            Real-time analysis and advanced features
          </div>
        </div>
        <div class="flex flex-col items-start justify-center gap-1 self-stretch">
          <div class="inline-flex items-center justify-start gap-1">
            <div class="justify-start text-[40px] font-semibold leading-10 text-slate-950">$19</div>
            <div class="justify-end text-sm font-normal leading-none text-slate-950">/ month</div>
          </div>
          <div class="inline-flex items-center justify-start gap-2">
            <div
              class="justify-start text-sm font-normal leading-[21px] text-slate-950 line-through"
            >
              $199 / month
            </div>
            <div
              class="flex items-center justify-center gap-2 rounded-[100px] bg-slate-950 px-4 py-1"
            >
              <div class="justify-start text-xs font-normal leading-[18px] text-white">
                For the first 777 Customers.
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start justify-start gap-2 self-stretch">
          <div class="inline-flex h-6 items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start text-base font-semibold leading-normal text-slate-950">
              Unlimited Sports Predictions.
            </div>
          </div>
          <div class="inline-flex h-6 items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-base font-semibold leading-normal text-slate-950">Every </span
              ><span class="text-base font-normal leading-normal text-slate-950">Sport</span
              ><span class="text-base font-semibold leading-normal text-slate-950">, Every </span
              ><span class="text-base font-normal leading-normal text-slate-950">Prediction</span
              ><span class="text-base font-semibold leading-normal text-slate-950">.</span>
            </div>
          </div>
          <div class="inline-flex h-6 items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-base font-semibold leading-normal text-slate-950"
                >Instant Picks</span
              ><span class="text-base font-normal leading-normal text-slate-950"
                >, No Waiting.</span
              >
            </div>
          </div>
          <div class="inline-flex h-6 items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-base font-normal leading-normal text-slate-950">Get the Edge </span
              ><span class="text-base font-semibold leading-normal text-slate-950"
                >Sports Books Fear.</span
              >
            </div>
          </div>
          <div class="inline-flex h-6 items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-base font-semibold leading-normal text-slate-950"
                >Sharp Bettors </span
              ><span class="text-base font-normal leading-normal text-slate-950">Use AI.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start gap-4 self-stretch p-6">
        <Button
          @click="requestPaymentLink"
          :disabled="loading"
          class="mt-3 w-full px-[10.50px] py-[10px] shadow"
        >
          <i v-if="loading" class="pi pi-spin pi-spinner"></i>
          <div class="text-[1.2rem] text-base font-bold uppercase">Get AI Predictions Now!</div>
        </Button>
        <div
          class="justify-start self-stretch text-center text-xs font-normal leading-[18px] text-slate-950"
        >
          Billed Monthly | Cancel Anytime
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: 'events', params: { type: target } }"
      class="justify-start self-stretch pb-5 text-center text-sm font-normal leading-[21px] text-slate-950 md:pb-0"
    >
      Skip
    </router-link>
  </AuthLayout>
</template>
