<script setup lang="ts">
import Builder1 from "@/assets/images/landing/builders/1.webp";
import Builder2 from "@/assets/images/landing/builders/2.webp";
import Builder3 from "@/assets/images/landing/builders/3.webp";
import BaseballImage from "@/assets/images/register/baseball_bg_1.webp";
import BasketballImage from "@/assets/images/register/basketball_bg_1.webp";
import DefaultImage from "@/assets/images/register/default_bg_1.webp";
import FootballImage from "@/assets/images/register/football_bg_1.webp";
import SoccerImage from "@/assets/images/register/soccer_bg_1.webp";
import GoogleIcon from "@/ui/components/icons/GoogleIcon.vue";
import StarIcon from "@/ui/components/icons/StarIcon.vue";
import StringHelper from "@/utils/stringHelper";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const isMobile = ref(false);
const isSafari = ref(false);
const bgImage = ref("");
const router = useRouter();

const props = defineProps<{
  bannerImage: string;
  variant?: string;
}>();

onMounted(() => {
  isMobile.value = window.innerWidth < 768;
  isSafari.value = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const variant = props.variant?.toLowerCase() || "default";

  if (variant) {
    switch (variant.toLowerCase()) {
      case "soccer":
        bgImage.value = SoccerImage;
        break;
      case "baseball":
        bgImage.value = BaseballImage;
        break;
      case "basketball":
        bgImage.value = BasketballImage;
        break;
      case "football":
        bgImage.value = FootballImage;
        break;
      default:
        bgImage.value = DefaultImage;
        break;
    }
  }
});
</script>

<template>
  <section id="home" class="mx-auto w-full md:max-w-[1312px] md:px-4">
    <div
      class="hidden flex-col items-center justify-between self-stretch pt-[90px] md:flex md:flex-row md:gap-36"
    >
      <div class="flex w-full flex-1 flex-col gap-6 p-6 md:w-auto md:p-0">
        <slot></slot>
      </div>
      <div
        :style="{
          backgroundImage: `url(${bannerImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: isMobile ? 'contain' : 'cover',
        }"
        class="relative h-[62vh] w-[90vw] md:h-[74vh] md:w-[471px]"
      >
        <div class="flex h-full flex-col items-end justify-center gap-3 pr-[24%] md:pr-[28%]">
          <div
            class="relative inline-flex flex-col items-center justify-center gap-1 rounded-lg bg-green-500 p-4 shadow-[0px_16px_32px_-8px_rgba(12,12,13,0.40)] outline outline-1 outline-offset-[-1px] outline-green-500"
          >
            <div class="inline-flex items-start justify-start gap-0.5">
              <div class="flex items-center justify-start gap-2">
                <div
                  class="justify-start text-xs font-bold uppercase leading-3 tracking-tight text-white"
                >
                  Model Accuracy
                </div>
              </div>
            </div>
            <div class="inline-flex items-center justify-center gap-1">
              <div class="h-5 w-5">
                <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2566_34392)">
                    <path
                      d="M13.3811 1.08493C13.2156 0.749488 12.874 0.537109 12.5 0.537109C12.126 0.537109 11.7844 0.749488 11.6189 1.08493L8.44154 7.52513L1.34197 8.55157C0.971828 8.60508 0.664164 8.86409 0.548337 9.21969C0.43251 9.57529 0.528608 9.96582 0.796225 10.2271L5.92933 15.238L4.71931 22.3147C4.65631 22.6831 4.80785 23.0554 5.11025 23.2752C5.41265 23.4949 5.81354 23.524 6.1445 23.3502L12.5 20.0136L18.8555 23.3502C19.1865 23.524 19.5874 23.4949 19.8898 23.2752C20.1922 23.0554 20.3437 22.6831 20.2807 22.3147L19.0707 15.238L24.2038 10.2271C24.4714 9.96582 24.5675 9.57529 24.4517 9.21969C24.3358 8.86409 24.0282 8.60508 23.658 8.55157L16.5585 7.52513L13.3811 1.08493Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2566_34392">
                      <rect x="0.5" width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="justify-start text-2xl font-bold text-white">85%+</div>
            </div>
            <div class="inline-flex items-start justify-start gap-0.5">
              <div class="flex items-center justify-start gap-2">
                <div
                  class="justify-start text-[8px] font-bold uppercase leading-3 tracking-tight text-white"
                >
                  300+ Games Predicted
                </div>
              </div>
            </div>
          </div>
          <div
            class="inline-flex flex-col items-center justify-center gap-1 rounded-lg bg-white p-4 shadow-[0px_16px_32px_-8px_rgba(12,12,13,0.40)] outline outline-1 outline-offset-[-1px] outline-white"
          >
            <div class="inline-flex items-start justify-start gap-0.5">
              <div class="flex items-center justify-start gap-2">
                <div
                  class="justify-start text-xs font-bold uppercase leading-3 tracking-tight text-slate-700"
                >
                  Last 10 Predictions
                </div>
              </div>
            </div>
            <div class="inline-flex items-center justify-center self-stretch">
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white first:ml-0"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-red-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
              <div
                class="-ml-[0.012rem] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.5px] bg-green-500 outline outline-2 outline-white"
              ></div>
            </div>

            <div class="inline-flex items-start justify-start gap-0.5">
              <div class="flex items-center justify-start gap-2">
                <div
                  class="justify-start text-[8px] font-bold uppercase leading-3 tracking-tight text-slate-700"
                >
                  300+ Games Predicted
                </div>
              </div>
            </div>
          </div>
          <div
            class="inline-flex flex-col items-center justify-center gap-1 rounded-lg bg-slate-800 p-4 shadow-[0px_16px_32px_-8px_rgba(12,12,13,0.40)] outline outline-1 outline-offset-[-1px] outline-slate-600"
          >
            <div class="inline-flex items-start justify-start gap-0.5">
              <div class="flex items-center justify-start gap-2">
                <div
                  class="justify-start text-xs font-bold uppercase leading-3 tracking-tight text-white"
                >
                  Model Builders
                </div>
              </div>
            </div>
            <div class="inline-flex items-center justify-start self-stretch">
              <div class="relative z-[4] -mr-2 h-11 w-11 rounded-[500px]">
                <img class="h-11 w-11 rounded-[500px]" :src="Builder1" />
              </div>
              <div class="relative z-[3] -mr-2 h-11 w-11 rounded-[500px]">
                <img class="h-11 w-11 rounded-[500px]" :src="Builder2" />
              </div>
              <div class="relative z-[2] -mr-1 h-11 w-11 rounded-[500px]">
                <img class="h-11 w-11 rounded-[500px]" :src="Builder3" />
              </div>
              <div class="relative z-[1] h-9 w-9 rounded-[500px]">
                <div class="h-9 w-9 rounded-[500px] outline outline-[3px] outline-lime-400">
                  <div
                    class="flex h-full items-center justify-center text-[10px] font-bold leading-[13.20px] tracking-tight text-white"
                  >
                    350+
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MOBILE BANNER -->
    <div
      :style="{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
      class="relative flex h-screen w-full flex-col items-start justify-end gap-2.5 bg-[#020617] md:hidden"
    >
      <div class="absolute z-[1] h-full w-full bg-gradient-to-b from-black/20 to-black"></div>

      <div
        :class="{ '!pb-24': isSafari, '!pb-18': !isSafari }"
        class="absolute bottom-0 z-[2] flex min-h-screen w-full flex-col items-start justify-end gap-2 self-stretch bg-gradient-to-b from-black/0 to-black p-4"
      >
        <div class="inline-flex items-center justify-start gap-2 self-stretch">
          <div class="flex items-center justify-start gap-1">
            <GoogleIcon class="h-[18px] w-[18px]" />
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
            <div class="relative h-3.5 w-3.5">
              <StarIcon class="h-[13.37px] w-3.5" />
            </div>
          </div>
          <div class="justify-center text-xs font-semibold leading-none text-lime-400">
            94% - Excellence
          </div>
        </div>
        <div class="justify-start self-stretch text-[48px] leading-[50px]">
          <span class="font-medium text-white">Accurate </span><br /><span
            v-if="variant?.length > 0 && variant?.toLowerCase() !== 'default'"
            class="font-bold text-white"
            >{{ StringHelper.ucFirst(variant!) }}</span
          ><span class="font-medium text-white"> Predictions,</span><br /><span
            class="font-medium text-lime-400"
          >
          </span
          ><span class="font-bold text-lime-400">Powered by AI.</span>
        </div>
        <div class="relative h-2 self-stretch"></div>
        <Button
          @click="router.push({ name: 'register', query: { target: variant! } })"
          class="inline-flex items-center justify-center self-stretch bg-lime-400 px-8 py-3"
        >
          <div
            class="justify-start text-[1.2rem] font-bold uppercase leading-tight tracking-tight text-[#1c1c1e]"
          >
            Get Your
            {{
              variant?.length > 0 && variant?.toLowerCase() !== "default"
                ? `${StringHelper.ucFirst(variant!)} Picks`
                : "AI Predictions"
            }}
            Now!
          </div>
        </Button>
      </div>
    </div>
  </section>
</template>
